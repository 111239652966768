import {useState, useCallback} from 'react';
import {useMutation} from 'graphql/client';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Button, Box, Typography, CircularProgress} from 'components/mui';
import UpdateUserClientOrganizationMenu from 'components/ui/layouts/headers/UpdateUserClientOrganizationMenu';
import User from 'core/User';

import AICPS from 'graphql/pages/aicps/Aicps.graphql';
import CLIENT_ORG_ACTIVE_AICP_COUNT from 'graphql/pages/client-organizations/activeAicpCount.graphql';
import UPDATE_USER_CLIENT_ORGANIZATION_MUTATION from 'graphql/pages/superuser/user/updateUserClientOrganization.graphql';
import USER from 'graphql/user/queries/user.graphql';
import USERS from 'graphql/user/queries/users.graphql';
import USER_ACTIVE_AICPS_COUNT from 'graphql/user/queries/userActiveAicpsCount.graphql';

import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';

const DropdownActivationButton = styled(Button)`
    color: white;
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
`;

const logger = new Logger('DesktopHeaderClientOrganization');

const DesktopHeaderClientOrganization = ({user}: {user: User}) => {
    const [updateUserClientOrg, {loading: isUpdateUserClientOrgLoading}] =
        useMutation(UPDATE_USER_CLIENT_ORGANIZATION_MUTATION, {
            refetchQueries: [
                {query: USER, variables: {userId: user.id}},
                {query: USERS},
                {query: USER_ACTIVE_AICPS_COUNT},
                {
                    query: CLIENT_ORG_ACTIVE_AICP_COUNT,
                    variables: {
                        clientOrganizationId: user.clientOrganization.id,
                    },
                },

                {query: AICPS},
            ],
            awaitRefetchQueries: true,
            onError: (error) => {
                logErrorMessages(error, logger);
            },
        });

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    if (isUpdateUserClientOrgLoading) {
        return (
            <Box display="flex" alignItems="center" flexWrap="nowrap" gap={1}>
                <Typography variant="h1" component="p">
                    {user.clientOrganization.name}
                </Typography>
                <CircularProgress size={14} />
            </Box>
        );
    }

    if (user.isSuperuser && user.hasValidSuperAdminEmail) {
        return (
            <>
                <DropdownActivationButton
                    data-testid="switch-client-org-button"
                    variant="text"
                    disableRipple
                    onClick={handleClick}
                >
                    <Typography variant="h1" component="p">
                        {user.clientOrganization.name}
                    </Typography>
                    <ArrowDropDownIcon />
                </DropdownActivationButton>

                <UpdateUserClientOrganizationMenu
                    user={user}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    updateUserClientOrg={updateUserClientOrg}
                    isUpdateUserClientOrgLoading={isUpdateUserClientOrgLoading}
                />
            </>
        );
    }

    return (
        <Typography variant="h1" component="p">
            {user.clientOrganization.name}
        </Typography>
    );
};

export default DesktopHeaderClientOrganization;
