import {themeOptions, BreakpointNames} from 'styles/theme/theme';

type BreakPointOptions = {
    [BreakpointNames.XS]: number;
    [BreakpointNames.SM]: number;
    [BreakpointNames.MD]: number;
    [BreakpointNames.LG]: number;
    [BreakpointNames.XL]: number;
};

function currentBreakpoint(
    windowWidth = 1400,
    breakpoints: BreakPointOptions = themeOptions.breakpoints.values
) {
    let currentBreakPoint = BreakpointNames.XS;
    const breakpointNames = Object.keys(breakpoints);

    breakpointNames.forEach((breakpointName) => {
        if (windowWidth >= breakpoints[breakpointName]) {
            currentBreakPoint = BreakpointNames[breakpointName];
        }
    });

    return currentBreakPoint;
}

export default currentBreakpoint;
