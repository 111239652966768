import {ApolloError, ServerError, ServerParseError} from '@apollo/client';
import {Error as CustomError, QueryResponse} from 'core/GraphQL/types';
import ErrorCode from 'utils/ErrorCode';
import {Logger, logger as defaultLogger} from 'utils/logger';

function getGraphqlErrorNotificationMessage(
    response: QueryResponse,
    defaultErrorNotificationMessage: string
) {
    const errorCode = response?.status?.error?.code;
    if (errorCode === ErrorCode.UNHANDLED) {
        return defaultErrorNotificationMessage;
    }
    return response?.status?.error?.message || defaultErrorNotificationMessage;
}

function transformUseQueryOutput(output, operationName) {
    const {data, loading, error} = output;
    const response = data?.[operationName];

    return {
        data: response,
        loading,
        failed: !!error || !response?.status?.success,
    };
}

export type ServerErrorResult = {
    message: string;
    code: string;
    extensions?: {[key: string]: unknown; code: string};
};

function isServerError(error: unknown): error is ServerError {
    return typeof error === 'object' && error !== null && 'result' in error;
}
function hasErrorMessage(error: unknown): error is Error | ServerParseError {
    return typeof error === 'object' && error !== null && 'message' in error;
}

function transformNetworkError(
    networkError: Error | ServerParseError | ServerError | null
) {
    const transformedNetworkErrors: CustomError[] = [];
    if (isServerError(networkError)) {
        const errors: ServerErrorResult[] =
            typeof networkError.result === 'string'
                ? networkError.result
                : networkError.result?.errors;

        errors.forEach((networkError) => {
            transformedNetworkErrors.push({
                code: networkError.extensions?.code
                    ? networkError.extensions?.code
                    : null,
                message: networkError.message,
            });
        });
    }
    if (hasErrorMessage(networkError) && !isServerError(networkError)) {
        transformedNetworkErrors.push({
            code: null,
            message: networkError.message,
        });
    }
    return transformedNetworkErrors;
}

function logErrorMessages(
    {graphQLErrors = [], clientErrors = [], networkError = null}: ApolloError,
    logger: Logger = defaultLogger
): void {
    graphQLErrors.forEach((error) => {
        logger.error(`[Grapqhl error]: ${error.message}`);
    });
    clientErrors.forEach((error) => {
        logger.error(`[Client error]: ${error.message}`);
    });

    const transformedNetworkErrors: CustomError[] | [] =
        transformNetworkError(networkError);

    transformedNetworkErrors.forEach((error) => {
        const errorCodeString = error.code
            ? ` | error code: ${error.code}`
            : '';
        logger.error(`[Network error]: ${error.message}${errorCodeString}`);
    });
}

export {
    getGraphqlErrorNotificationMessage,
    transformUseQueryOutput,
    logErrorMessages,
};
