export function sortByKeyAlphabetically<ArrayType>(
    arr: ArrayType[],
    key: string
): ArrayType[] {
    return arr.sort((a, b) => {
        const lowercaseA = String(a[key]).toLowerCase();
        const lowercaseB = String(b[key]).toLowerCase();

        if (lowercaseA < lowercaseB) {
            return -1;
        }
        if (lowercaseA > lowercaseB) {
            return 1;
        }
        return 0;
    });
}

export function randomChoice(messages: string[]): string {
    return messages[Math.floor(Math.random() * messages.length)];
}
