import {Tooltip} from 'components/mui';
import PropTypes from 'prop-types';

function ArrowTooltip({placement, content, color = '#008542', children}) {
    return (
        <Tooltip
            placement={placement}
            title={content}
            color={color}
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: color,
                        color: 'white',
                        '& .MuiTooltip-arrow': {
                            color: color,
                        },
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    );
}

ArrowTooltip.propTypes = {
    color: PropTypes.string,
    placement: PropTypes.string,
    content: PropTypes.any,
    children: PropTypes.any,
};

export default ArrowTooltip;
