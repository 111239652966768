import {
    addNotificationMessage,
    removeNotificationMessage,
    clearNotificationMessages,
    deleteNotificationMessage,
} from 'actions/notification';

import {
    NotificationInput,
    NotificationMessageType,
} from 'core/Notification/types';

class Notification {
    _postNotification({message, type, options}: NotificationInput): string {
        const notificationMessage = addNotificationMessage({
            message,
            type,
            options,
        });

        if (options.removeAfter) {
            setTimeout(() => {
                this.delete(notificationMessage.id);
            }, options.removeAfter * 1000);
        }

        return notificationMessage.id;
    }
    error(message: string, options = {}) {
        return this._postNotification({
            message,
            type: NotificationMessageType.ERROR,
            options,
        });
    }
    info(message: string, options = {}) {
        return this._postNotification({
            message,
            type: NotificationMessageType.INFO,
            options,
        });
    }
    warning(message: string, options = {}) {
        return this._postNotification({
            message,
            type: NotificationMessageType.WARNING,
            options,
        });
    }
    success(message: string, options = {}) {
        return this._postNotification({
            message,
            type: NotificationMessageType.SUCCESS,
            options,
        });
    }
    remove(id: string) {
        removeNotificationMessage(id);
    }
    delete(id: string) {
        deleteNotificationMessage(id);
    }
    clearMessages() {
        clearNotificationMessages();
    }
}

const notification = new Notification();

export {notification as Notification};
