import styled from 'styled-components';

const MenuItemWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: #121819;
    height: 45px;
    padding: 0 10px;

    &.active {
        .menu-item__label {
            color: white;
        }
    }

    .menu-item {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        text-decoration: none;

        &__label {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    @media (min-width: ${(props) => props.theme.mobileToDesktopBreakpoint}px) {
        display: flex;
        height: 35px;
        transition: border 0.25s ease-in-out 0.08s;
        border-left: 3px solid #121819;
        margin: 10px 0;

        &:hover {
            border-left: 3px solid #008542;
        }

        &.active {
            border-left: 3px solid #008542;
            background-color: #1a2520;
            .menu-item__label {
                color: white;
            }
        }

        .menu-item {
            display: flex;
            flex-flow: row;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: flex-start;
            padding-left: 7px;

            &__label {
                display: flex;
                justify-content: flex-start;
                margin-left: 15px;
                color: #61696b;
                font-size: 1.4rem;
                line-height: 24px;
                min-width: 180px;
            }
        }
    }
`;

const MenuItemsGroup = styled.div`
    display: flex;
    width: 100%;

    .menu-item-wrapper {
        cursor: pointer;
    }
`;

export {MenuItemWrapper, MenuItemsGroup};
