import styled from 'styled-components';
import {Box} from 'components/mui';

const NavigationResponsiveBox = styled(Box)<{$navigationExpanded: boolean}>`
    width: ${(props) =>
        props.$navigationExpanded
            ? `calc(100% - ${props.theme.componentVariables.expandedSideNav.width}px)`
            : `calc(100% - ${props.theme.componentVariables.collapsedSideNav.width}px)`};

    transition: ${(props) =>
        props.$navigationExpanded
            ? `${props.theme.transitions.create(['width', 'margin'], {
                  easing: props.theme.transitions.easing.sharp,
                  duration: props.theme.transitions.duration.enteringScreen,
              })}`
            : `${props.theme.transitions.create(['width', 'margin'], {
                  easing: props.theme.transitions.easing.sharp,
                  duration: props.theme.transitions.duration.leavingScreen,
              })}`};
`;

export {NavigationResponsiveBox};
