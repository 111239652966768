import {createContext, useContext} from 'react';
import User from 'core/User';
import type {CustomExegraphicLens} from 'core/ClientOrganization/types';

export const UserContext = createContext<User | null>(null);

export const useUserContext = () => useContext(UserContext);

type SetUserContextClientOrgInput = {
    id?: string;
    name?: string;
    customExegraphicLenses?: CustomExegraphicLens[];
    contractDetails?: {
        companyDownloadLimit: number | null;
    };
    downloadCounts?: {
        uniqueCompanyDownloadCount?: number | null;
        totalCompanyDownloadCount?: number | null; // Made optional
    };
    hasContextProfileAnswers?: boolean;
};

export type UserContextInput = {
    id?: string;
    name?: string;
    clientOrganization?: SetUserContextClientOrgInput;
    isSuperuser?: boolean;
    hasValidSuperAdminEmail?: boolean;
};

export const setUserContext = (context: UserContextInput = {}) => {
    throw Error(
        `this function is for testing only: ${JSON.stringify(context)}`
    );
};

export const setSuperuserContext = (context: UserContextInput = {}) => {
    throw Error(
        `this function is for testing only: ${JSON.stringify(context)}`
    );
};
