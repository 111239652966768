import Head from 'next/head';
import PropTypes from 'prop-types';

const HeadTitle = ({children}) => {
    const text = getTextContent(children).trim();
    const subtitle = text.length > 0 ? ` - ${text}` : '';

    const title = `Rev${subtitle}`;

    return (
        <Head>
            <title key="head-title">{title}</title>
        </Head>
    );
};

function getTextContent(element) {
    if (!element) {
        return '';
    }
    if (typeof element === 'string') {
        return element;
    }
    const children = element.props && element.props.children;
    if (children instanceof Array) {
        return children.map(getTextContent).join('');
    }
    return getTextContent(children);
}

HeadTitle.propTypes = {
    children: PropTypes.any,
};

export default HeadTitle;
