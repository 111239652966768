import styled from 'styled-components';
import {PropTypes} from 'prop-types';
import {DefaultDesktopHeader} from 'components/ui/layouts/headers/DefaultDesktopHeader';
import {NotificationsContext} from 'components/contexts/notifications';
import NotificationMessages from 'components/ui/notification/NotificationMessages';
import DefaultDesktopMenu from 'components/ui/layouts/menus/DefaultDesktopMenu';
import {Grid} from 'components/mui';
import {NavigationResponsiveBox} from 'components/ui/layouts/DesktopLayout.styles';
import usePageDimensionsContext from 'components/hooks/usePageDimensionsContext';

const ContentBox = styled(NavigationResponsiveBox)`
    margin-left: ${(props) =>
        props.$navigationExpanded
            ? `${props.theme.componentVariables.expandedSideNav.width}px`
            : `${props.theme.componentVariables.collapsedSideNav.width}px`};
`;

const DesktopColumnsRow = styled(Grid)`
    .main-content {
        width: 100%;
        background-color: ${({theme}) => theme.palette.background.default};
    }

    .content-wrapper {
        background-color: #f0f0f0;
        margin-top: ${({theme}) => theme.componentVariables.topNav.height}px;
        min-height: calc(
            100vh - ${({theme}) => theme.componentVariables.topNav.height}px
        );
    }

    .notifications-wrapper {
        position: fixed;
        display: flex;
        justify-content: center;
        width: 100%;
        left: 0;
        bottom: 0;
    }
`;

const DefaultDesktopLayout = ({pageId, children, contentStyles}) => {
    const {
        navigationExpanded,
        setNavigationExpanded,
        calculatePageContentWidth,
    } = usePageDimensionsContext();

    return (
        <>
            <DefaultDesktopMenu
                pageId={pageId}
                navigationExpanded={navigationExpanded}
                setNavigationExpanded={setNavigationExpanded}
            />

            <ContentBox $navigationExpanded={navigationExpanded}>
                <DesktopColumnsRow container>
                    <Grid
                        item
                        className="main-content"
                        data-testid={`page-${pageId}`}
                    >
                        <DefaultDesktopHeader
                            navigationExpanded={navigationExpanded}
                        />

                        <div className="content-wrapper" style={contentStyles}>
                            {children}
                        </div>

                        <NotificationsContext.Consumer>
                            {(notificationMessages) => {
                                if (
                                    !notificationMessages ||
                                    notificationMessages.length === 0
                                ) {
                                    return null;
                                }
                                return (
                                    <NotificationMessages
                                        messages={notificationMessages}
                                        pageContentWidth={calculatePageContentWidth()}
                                    />
                                );
                            }}
                        </NotificationsContext.Consumer>
                    </Grid>
                </DesktopColumnsRow>
            </ContentBox>
        </>
    );
};

DefaultDesktopLayout.propTypes = {
    pageId: PropTypes.string.isRequired,
    children: PropTypes.node,
    currentUser: PropTypes.object,
    contentStyles: PropTypes.object,
};

export default DefaultDesktopLayout;
