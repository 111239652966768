import NOTIFICATION_MESSAGES_QUERY from 'graphql/components/ui/notification/notificationMessages.graphql';
import client from 'components/apollo/client';
import {v4 as uuidv4} from 'uuid';
import {logger} from 'utils/logger';
import {localStorageUtil} from 'utils/localStorageUtil';
import {
    NotificationInput,
    NotificationMessage,
    NotificationMessageStatus,
    NOTIFICATION_MESSAGES,
} from 'core/Notification/types';

type NotificationMessageQueryResponse = {
    notificationMessages: NotificationMessage[] | [];
};

function writeNotificationMessages(
    updatedMessages: NotificationMessage[]
): void {
    client.writeQuery({
        query: NOTIFICATION_MESSAGES_QUERY,
        data: {
            [NOTIFICATION_MESSAGES]: updatedMessages,
        },
    });

    localStorageUtil.set(NOTIFICATION_MESSAGES, updatedMessages);
}

function readNotificationMessages(): NotificationMessage[] {
    const data: NotificationMessageQueryResponse = client.readQuery({
        query: NOTIFICATION_MESSAGES_QUERY,
    }) || {
        notificationMessages: [],
    };

    return [...data.notificationMessages];
}

const addNotificationMessage = (notification: NotificationInput) => {
    const {message, type, options} = notification;
    const id = options.id || uuidv4();

    try {
        const newNotificationMessage: NotificationMessage = {
            id,
            type,
            options,
            content: message,
            status: NotificationMessageStatus.DISPLAYED,
        };

        if (typeof message !== 'string') {
            logger.error('attempted to render a non-string notification');
            throw Error('An unexpected error occured.');
        }

        const currentMessages = readNotificationMessages();
        writeNotificationMessages([...currentMessages, newNotificationMessage]);

        return newNotificationMessage;
    } catch (e) {
        logger.error(
            `unable to add notification message ${message} of type ${type}`
        );
        logger.error(e);
    }
};

const removeNotificationMessage = (id: string) => {
    const notificationMessages = readNotificationMessages();

    const updatedMessages = notificationMessages.map((message) => {
        const updatedMessage = {...message};
        if (message.id === id) {
            updatedMessage.status = NotificationMessageStatus.REMOVED;
        }

        return updatedMessage;
    });

    writeNotificationMessages(updatedMessages);
};

const deleteNotificationMessage = (id: string) => {
    const notificationMessages = readNotificationMessages();
    const filteredMessages = notificationMessages.filter(
        (messageToFilter) => messageToFilter.id !== id
    );

    writeNotificationMessages(filteredMessages);
};

const clearNotificationMessages = () => {
    writeNotificationMessages([]);
};

export {
    addNotificationMessage,
    removeNotificationMessage,
    clearNotificationMessages,
    deleteNotificationMessage,
};
