import React, {Fragment} from 'react';
import {MenuIcon} from 'components/ui/menu/MenuIcon';

type MenuItemLabelProps = {
    menuItemId: string;
    children?: React.ReactNode;
    isActive?: boolean;
};

const MenuItemLabel = ({
    menuItemId,
    children,
    isActive = false,
}: MenuItemLabelProps) => {
    return (
        <Fragment>
            <span className="menu-item__icon">
                <MenuIcon menuItemId={menuItemId} isActive={isActive} />
            </span>
            <span className="menu-item__label">{children}</span>
        </Fragment>
    );
};

export {MenuItemLabel};
