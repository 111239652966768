import {useQuery} from 'graphql/client';
import ReleaseNotes from 'graphql/pages/release-notes/ReleaseNotes.graphql';
import {ReleaseNotesPayload} from 'src/types/graphql-types';
import {logErrorMessages} from 'utils/graphqlResponse';
import {Logger} from 'utils/logger';

const logger = new Logger('useReleaseNotesQuery');

const useReleaseNotesQuery = () => {
    const {data, loading, error} = useQuery<{
        releaseNotes: ReleaseNotesPayload;
    }>(ReleaseNotes, {
        onError(error) {
            logErrorMessages(error, logger);
        },
    });

    const releaseNotes = data?.releaseNotes.releaseNotes || [];

    return {
        releaseNotes,
        version: releaseNotes[0]?.version || null,
        loading,
        error,
    };
};

export default useReleaseNotesQuery;
