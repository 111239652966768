import {useEffect} from 'react';
import styled from 'styled-components';
import {
    Alert,
    Fade,
    Typography,
    Button,
    Box,
    CircularProgress,
} from 'components/mui';
import usePageDimensionsContext from 'components/hooks/usePageDimensionsContext';
import {Notification} from 'core/Notification';
import {NotificationMessage} from 'core/Notification/types';
import {Close} from '@mui/icons-material';
import {useRouter} from 'next/router';

const FADE_TIMEOUT_MS = 250;

const StyledAlert = styled(Alert)`
    background-color: white;
    display: flex;
    align-items: center;
    min-width: 70%;
    margin: 0 auto 8px;
    max-width: 85%;

    &.hidden {
        display: none;
    }
`;

const ClearButtonContainer = styled(Box)`
    min-width: 70%;
    margin: 0 auto 8px;
    max-width: 85%;
`;

const ClearAllButton = styled(Button)`
    background: ${({theme}) => theme.palette.background.content};
    color: ${({theme}) => theme.palette.text.default};
    padding: 1px 4px;
    font-size: 11px;
    min-width: 50px;

    &:hover {
        background-color: ${({theme}) => theme.palette.background.selected};
    }
`;

const NotificationMessageList = styled.div<{$pageContentWidth: string}>`
    overflow: visible;
    padding: 0;
    margin: 0 auto;
    position: fixed;
    width: 100%;
    z-index: 9000;

    @media (min-width: ${(props) => props.theme.mobileToDesktopBreakpoint}px) {
        width: ${(props) => props.$pageContentWidth};
    }

    &.bottom {
        bottom: 0;
    }

    &.top {
        top: 0;
    }
`;

const StyledButton = styled(Button)`
    font-weight: ${({theme}) => theme.typography.fontWeightBold};
`;

interface NotificationMessagesProps {
    messages: NotificationMessage[];
}

function NotificationMessages({messages}: NotificationMessagesProps) {
    const router = useRouter();
    const {calculatePageContentWidth} = usePageDimensionsContext();
    const pageContentWidth = calculatePageContentWidth();
    useEffect(() => {
        const onRouteChangeStart = () => {
            messages.forEach((message) => {
                const {options} = message;
                if (!options.preserveOnPageChange) {
                    Notification.delete(message.content);
                }
            });
        };

        router.events.on('routeChangeStart', onRouteChangeStart);

        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart);
        };
    });

    if (!messages || messages.length === 0) {
        return null;
    }

    return (
        <NotificationMessageList
            className="bottom"
            $pageContentWidth={pageContentWidth}
        >
            {messages.map((message) => {
                const {id, type, content, status, options = {}} = message;
                const {processing, title, preserveOnPageChange} = options;
                return (
                    <Fade
                        key={id}
                        in={status !== 'removed'}
                        addEndListener={() => {
                            if (status === 'removed' && !preserveOnPageChange) {
                                setTimeout(() => {
                                    Notification.delete(message.id);
                                }, FADE_TIMEOUT_MS);
                            }
                        }}
                    >
                        <StyledAlert
                            style={{
                                display: 'flex',
                            }}
                            severity={type}
                            variant="outlined"
                            icon={processing && <CircularProgress size={12} />}
                            action={
                                <StyledButton
                                    color="inherit"
                                    size="large"
                                    variant="text"
                                    onClick={() => {
                                        Notification.remove(message.id);
                                    }}
                                >
                                    Dismiss
                                </StyledButton>
                            }
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-evenly"
                            >
                                {title && (
                                    <Box mr={1}>
                                        <Typography>
                                            <strong>{title}: </strong>
                                        </Typography>
                                    </Box>
                                )}
                                <Box>
                                    <Typography>{content}</Typography>
                                </Box>
                            </Box>
                        </StyledAlert>
                    </Fade>
                );
            })}
            {messages.length > 1 && (
                <ClearButtonContainer display="flex" justifyContent="flex-end">
                    <ClearAllButton
                        type="button"
                        variant="text"
                        data-testid="clear-notifications-button"
                        onClick={() => Notification.clearMessages()}
                    >
                        clear{' '}
                        <Box ml={0.5}>
                            <Close fontSize="inherit" />
                        </Box>
                    </ClearAllButton>
                </ClearButtonContainer>
            )}
        </NotificationMessageList>
    );
}

export default NotificationMessages;
