import {useContext} from 'react';
import {PageDimensionsContext} from 'components/providers/PageDimensionsProvider';

export default function usePageDimensionsContext() {
    const pageDimensionsContext = useContext(PageDimensionsContext);
    if (pageDimensionsContext === undefined) {
        throw Error('PageDimensionsContext is required for this component');
    }

    return pageDimensionsContext;
}
