import {Box, Drawer as MuiDrawer, IconButton} from 'components/mui';
import {styled, Theme, CSSObject, useTheme} from '@mui/material/styles';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Image from 'next/legacy/image';
import {mainDesktopNavItems} from 'components/ui/layouts/menus/menu.config';
import {Navigation} from 'components/ui/navigation/Navigation';
import ReleaseNotesSideBarLink from './ReleaseNotesSidebarLink';

const navItems = mainDesktopNavItems;

const DrawerHeaderContainer = styled(Box)(({theme}) => ({
    display: 'block',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
    marginBottom: theme.spacing(1),
}));

const openedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.primary.black,
    width: theme.componentVariables.expandedSideNav.width,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.primary.black,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${theme.componentVariables.collapsedSideNav.width}px`,
    [theme.breakpoints.up('sm')]: {
        width: `${theme.componentVariables.collapsedSideNav.width}px`,
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    width: theme.componentVariables.expandedSideNav.width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface DefaultDesktopMenuProps {
    pageId: string;
    navigationExpanded: boolean;
    setNavigationExpanded: (expanded: boolean) => void;
}

export default function DefaultDesktopMenu({
    pageId,
    navigationExpanded,
    setNavigationExpanded,
}: DefaultDesktopMenuProps) {
    const {palette} = useTheme();

    const toggleDrawerExpansion = () => {
        setNavigationExpanded(!navigationExpanded);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <Drawer variant="permanent" open={navigationExpanded}>
                <DrawerHeaderContainer>
                    <Image src="/rev-logo.svg" width={30} height={30} />
                    {navigationExpanded ? (
                        <Box display="inline-block" paddingLeft={3.5}>
                            <Image
                                src="/rev-logo-type-white.svg"
                                width={70}
                                height={30}
                            />
                        </Box>
                    ) : null}
                </DrawerHeaderContainer>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        flexBasis={0}
                    >
                        <Navigation
                            activeNavItem={pageId}
                            navItems={navItems}
                            navigationExpanded={navigationExpanded}
                        />
                    </Box>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        flexDirection={'column'}
                        paddingLeft={1}
                        paddingBottom={1.5}
                    >
                        <ReleaseNotesSideBarLink
                            isExpanded={navigationExpanded}
                        />
                        <Box>
                            <IconButton onClick={toggleDrawerExpansion}>
                                {navigationExpanded ? (
                                    <Box color={palette.background.default}>
                                        <ArrowBackRoundedIcon color="inherit" />
                                    </Box>
                                ) : (
                                    <Box color={palette.background.default}>
                                        <ArrowForwardRoundedIcon color="inherit" />
                                    </Box>
                                )}
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}
