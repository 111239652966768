import {createContext, ReactNode, useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import currentBreakpoint from 'styles/utils/currentBreakpoint';
import {BreakpointNames} from 'styles/theme/theme';
import {localStorageUtil} from 'utils/localStorageUtil';

export type PageDimensionsContextValue = {
    windowWidth: number;
    windowHeight: number;
    navigationWidth: number;
    navigationExpanded: boolean;
    setNavigationExpanded: (expanded: boolean) => void;
    calculatePageHeight: ({padding}: {padding?: number}) => string;
    calculatePageContentWidth: () => string;
    currentBreakpoint: BreakpointNames;
};

const initialContext: PageDimensionsContextValue = {
    windowWidth: 0,
    windowHeight: 0,
    navigationWidth: 0,
    navigationExpanded: false,
    setNavigationExpanded: () => null,
    calculatePageHeight: () => '0px',
    calculatePageContentWidth: () => '0px',
    currentBreakpoint: BreakpointNames.XS,
};

const PageDimensionsContext = createContext(initialContext);

interface PageDimensionsProviderProps {
    children: ReactNode;
}

function getIsDesktopNavigationExpanded(): boolean {
    const isDesktopNavigationExpanded: boolean | null = JSON.parse(
        window?.localStorage?.getItem('isDesktopNavigationExpanded')
    );
    if (isDesktopNavigationExpanded === null) {
        return true;
    }
    return isDesktopNavigationExpanded;
}

const PageDimensionsProvider = (props: PageDimensionsProviderProps) => {
    const {breakpoints, componentVariables} = useTheme();
    const [windowWidth, setWindowWidth] = useState(null);
    const [windowHeight, setWindowHeight] = useState(null);
    const [navigationExpanded, setNavigationExpanded] = useState(
        getIsDesktopNavigationExpanded()
    );

    const [navigationWidth, setNavigationWidth] = useState(null);

    function calculatePageHeight({padding = 0}) {
        return `${windowHeight - padding - componentVariables.topNav.height}px`;
    }

    function calculatePageContentWidth() {
        const sideNavWidth = navigationExpanded
            ? componentVariables.expandedSideNav.width
            : componentVariables.collapsedSideNav.width;

        return `${windowWidth - sideNavWidth}px`;
    }

    useEffect(() => {
        function updateWindowDimensions() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        }

        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return function cleanup() {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    useEffect(() => {
        localStorageUtil.set('isDesktopNavigationExpanded', navigationExpanded);
        setNavigationWidth(
            navigationExpanded
                ? componentVariables.expandedSideNav.width
                : componentVariables.collapsedSideNav.width
        );
    }, [navigationExpanded]);

    return (
        <PageDimensionsContext.Provider
            value={{
                windowWidth,
                windowHeight,
                navigationExpanded,
                setNavigationExpanded,
                navigationWidth,
                calculatePageHeight,
                calculatePageContentWidth,
                currentBreakpoint: currentBreakpoint(
                    windowWidth,
                    breakpoints.values
                ),
            }}
        >
            {props.children}
        </PageDimensionsContext.Provider>
    );
};

export {PageDimensionsProvider, PageDimensionsContext};
