import React from 'react';
import Router from 'next/router';
import {useQuery, useMutation} from 'graphql/client';
import {signOut} from 'next-auth/client';
import PropTypes from 'prop-types';
import USER from 'graphql/user/queries/user.graphql';
import LOGOUT from 'graphql/user/mutations/logout.graphql';
import {LOGIN_URL} from 'components/util/routeConstants';
import {identifyUser} from 'components/tracking/useTracking';

function UserQueryProvider(props) {
    const [logout] = useMutation(LOGOUT, {
        onError() {
            signOut();
            Router.push(LOGIN_URL);
        },
        async onCompleted() {
            await signOut();
            Router.push(LOGIN_URL);
        },
    });
    const {data, loading, error, refetch} = useQuery(USER, {
        variables: {userId: props.userId},
        fetchPolicy: 'cache-first',
    });

    if (data?.user?.user) {
        identifyUser(data.user.user);
    }

    if (loading) return null;

    if (error || data?.user?.status?.error) {
        logout();
        return null;
    }

    return (
        <>
            {props.children({
                user: {
                    ...data.user.user,
                    refetch: () => refetch({userId: props.userId}),
                },
            })}
        </>
    );
}

UserQueryProvider.propTypes = {
    userId: PropTypes.string.isRequired,
    children: PropTypes.func,
};

export {UserQueryProvider};
