import {useState} from 'react';
import {FetchResult} from '@apollo/client';
import {useQuery} from 'graphql/client';
import styled from 'styled-components';
import {Box, Button, Typography, Popover} from 'components/mui';
import CLIENT_ORGANIZATIONS from 'graphql/pages/client-organizations/clientOrganizations.graphql';
import {TextFieldSelectDropdown} from 'components/ui/forms/TextFieldSelectDropdown';
import {logErrorMessages} from 'utils/graphqlResponse';
import {sortByKeyAlphabetically} from 'components/util/arrayUtil';
import {Logger} from 'utils/logger';
import User from 'core/User';
const logger = new Logger('UpdateUserClientOrganizationMenu');

export type UpdateUserClientOrgMutationTypes = {
    clientOrganizationId: string;
};

const StyledTextFieldSelectDropdown = styled(TextFieldSelectDropdown)`
    width: 300px;
`;

const UpdateUserClientOrganizationMenu = ({
    user,
    anchorEl,
    handleClose,
    updateUserClientOrg,
    isUpdateUserClientOrgLoading,
}: {
    user: User;
    anchorEl: HTMLElement;
    handleClose: () => void;
    updateUserClientOrg: ({
        variables,
    }: {
        variables: UpdateUserClientOrgMutationTypes;
    }) => Promise<FetchResult<unknown>>;
    isUpdateUserClientOrgLoading: boolean;
}) => {
    const [selectedClientOrganization, setSelectedClientOrganization] =
        useState(user?.clientOrganization);
    const {data} = useQuery(CLIENT_ORGANIZATIONS, {
        variables: {
            limit: 10000,
            offset: 0,
            filterNamesBy: '',
        },
        onError: (error) => {
            logErrorMessages(error, logger);
        },
    });

    const clientOrganizations =
        data?.clientOrganizations.clientOrganizations ?? [];

    const sortedClientOrganizations = sortByKeyAlphabetically(
        [...clientOrganizations],
        'name'
    );

    const onSubmit = () => {
        const variables = {
            clientOrganizationId: selectedClientOrganization.id,
        };
        updateUserClientOrg({variables})
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                logErrorMessages(error, logger);
            });
    };

    return (
        <Popover
            data-testid="update-client-org-dropdown"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box
                p={3}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                alignItems="center"
            >
                <Typography data-testid="menu-header" variant="h6">
                    Switch Client Organization
                </Typography>
                <hr />
                <Box mb={3} display="flex" alignSelf="start">
                    <StyledTextFieldSelectDropdown
                        autoComplete
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        id="client-org-autocomplete"
                        options={sortedClientOrganizations}
                        optionLabelField="name"
                        optionKeyField="id"
                        label="Client Organization"
                        value={selectedClientOrganization}
                        onChange={(event, newValue) =>
                            setSelectedClientOrganization(newValue)
                        }
                    />
                </Box>
                <Box display="flex" alignSelf="end">
                    <Button
                        type="submit"
                        disabled={isUpdateUserClientOrgLoading}
                        onClick={() => {
                            onSubmit();
                            handleClose();
                        }}
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        </Popover>
    );
};
export default UpdateUserClientOrganizationMenu;
