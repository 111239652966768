import {Box, Typography} from 'components/mui';
import {useUserContext} from 'components/contexts/user';
import {useTheme} from '@mui/material/styles';
import styled from 'styled-components';
import DesktopHeaderDropdown from 'components/ui/layouts/headers/DesktopHeaderDropdown';
import DesktopHeaderClientOrganization from 'components/ui/layouts/headers/DesktopHeaderClientOrganization';
import {NavigationResponsiveBox} from 'components/ui/layouts/DesktopLayout.styles';

const HeaderWrapper = styled(NavigationResponsiveBox)<{
    $navigationExpanded: boolean;
}>`
    .MuiTypography-body1 {
        color: ${({theme}) => theme.palette.primary.light};
    }
    .MuiTypography-h1 {
        font-size: 1.6rem;
        color: ${({theme}) => theme.palette.primary.light};
    }
    .MuiTypography-h6 {
        color: ${({theme}) => theme.palette.primary.light};
        font-size: 1.4rem;
    }
`;

const DefaultDesktopHeader = ({
    navigationExpanded,
}: {
    navigationExpanded: boolean;
}) => {
    const user = useUserContext();

    const {componentVariables} = useTheme();

    return (
        <HeaderWrapper
            position="fixed"
            bgcolor="secondary.dark"
            zIndex={900}
            height={`${componentVariables.topNav.height}px`}
            $navigationExpanded={navigationExpanded}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                height="100%"
                p={[2, 3]}
            >
                <Box display="flex" alignItems="center">
                    <DesktopHeaderClientOrganization user={user} />
                </Box>
                <Box display="flex" alignItems="center" height="100%">
                    <Typography color="inherit">Welcome Back</Typography>
                    <DesktopHeaderDropdown user={user} />
                </Box>
            </Box>
        </HeaderWrapper>
    );
};

export {DefaultDesktopHeader};
