import {Box, Typography, CircularProgress} from 'components/mui';
import {styled} from '@mui/material/styles';
import Link from 'next/link';
import ArticleIcon from '@mui/icons-material/Article';
import useReleaseNotesQuery from 'components/ui/pages/release-notes/useReleaseNotesQuery';

const ReleaseNotesContainer = styled(Box)`
    text-align: center;
    &,
    & a {
        color: ${({theme}) => theme.palette.primary.main};
    }
`;

const ReleaseNotesSideBarLink = ({isExpanded}: {isExpanded: boolean}) => {
    const {version, loading, error} = useReleaseNotesQuery();

    if (loading) {
        return (
            <ReleaseNotesContainer
                display="flex"
                justifyContent="center"
                width="100%"
            >
                <CircularProgress size={20} />
            </ReleaseNotesContainer>
        );
    }

    if (error || !version) {
        return null;
    }

    return isExpanded ? (
        <ReleaseNotesContainer p={2} width="100%">
            <Typography mt={1} component="div" sx={{fontSize: '1.4rem'}}>
                <Link href="/release-notes" passHref={true}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                    >
                        <Box mr={1}>
                            <ArticleIcon />
                        </Box>
                        <span>Release notes</span>
                    </Box>
                </Link>
            </Typography>
            <Typography color="primary.disabled" sx={{fontSize: '1.4rem'}}>
                v{version}
            </Typography>
        </ReleaseNotesContainer>
    ) : (
        <ReleaseNotesContainer
            display="flex"
            alignItems={'center'}
            justifyContent={'center'}
            width="100%"
            p={0}
            ml={-0.5}
        >
            <Typography sx={{fontSize: '1.4rem'}} component="div">
                <Link href="/release-notes" passHref={true}>
                    <ArticleIcon />
                    <br />
                    {version}
                </Link>
            </Typography>
        </ReleaseNotesContainer>
    );
};

export default ReleaseNotesSideBarLink;
