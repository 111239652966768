import {
    CONNECTORS_URL,
    SETTINGS_URL,
    EXPORT_HISTORY_URL,
    SUPERUSER,
    SAVED_SIGNALS_URL,
} from 'components/util/routeConstants';

export interface DropdownMenuItem {
    label: string;
    id: string;
    href: string;
}

export interface FeatureFlagsDropdownMenuItems {
    [key: string]: DropdownMenuItem;
}

const mainHeaderDropdownMenuItems: DropdownMenuItem[] = [
    {
        label: 'Account Settings',
        id: 'account-settings',
        href: SETTINGS_URL,
    },
    {
        label: 'Connectors',
        id: 'connectors',
        href: CONNECTORS_URL,
    },
    {
        label: 'Export History',
        id: 'export-history',
        href: EXPORT_HISTORY_URL,
    },
    {
        label: 'Signal Management',
        id: 'signals',
        href: SAVED_SIGNALS_URL,
    },
];

const superAdminDropdownMenuItems: DropdownMenuItem[] = [
    {
        label: 'Client Organizations',
        id: 'client-organizations',
        href: SUPERUSER.CLIENT_ORGANIZATIONS_URL,
    },
    {
        label: 'Users',
        id: 'users',
        href: SUPERUSER.USERS_URL,
    },
];

export {mainHeaderDropdownMenuItems, superAdminDropdownMenuItems};
